import React from "react";
import { css } from "@emotion/react";

import Text from "atoms/Text";
import Flexbox from "atoms/Flexbox";
import Input from "molecules/Input";
import WidgetContainer from "atoms/WidgetContainer";
import { FlexboxItem } from "atoms/Flexbox/Flexbox";

import Animated from "molecules/Animated";
import ResponsiveDiv from "atoms/ResponsiveDiv";
import Button from "atoms/Button";
import Textarea from "molecules/Textarea";
import { MixedType, SchemaModel, StringType } from "schema-typed";
import { ContactUsFormData, ContactUsFormDataValidationError } from "./types";
import FilledTick from "icons/FilledTick";
import { createContactUsForm } from "services/contactUs";
import Spinner from "atoms/Spinner";

const styles = {
	contactFormWrapper: css`
		background-color: var(--color-dark-gray);
		border-radius: var(--spacing-xxl);
		padding: var(--spacing-xxl);
		position: relative;
		overflow: hidden;
	`,
	contactFormLoader: css`
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		background-color: rgba(0,0,0,.4);
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
	`
}

const contactUsSchemaModel = SchemaModel({
	fullName: StringType().isRequired('Full Name is required.'),
	email: StringType().isRequired('Email is required.').isEmail('Verify format: johndoe@ymail.com'),
	phoneNumber: StringType()
		.pattern(/^[6-9]\d{9}$/, "Please enter valid Phone Number.")
		.rangeLength(10, 10, "Please enter a 10-digit Phone Number"),
	message: StringType()
		.isRequired('Message is required')
		.minLength(10, "Message should be minimum 10 characters long.")
		.maxLength(200, "Message should be maximum 200 characters long.")
});

const initialDetailsValidation: ContactUsFormDataValidationError = {
	fullName: { hasError: false },
	email: {
		hasError: false,
	},
	phoneNumber: { hasError: false },
	message: { hasError: false },
};

const SubmittedState = () => {
	return (
		<Flexbox direction="row" justify="space-between" align="center">
			<FilledTick />
			<Text ml="xs" color="green500" fontStyleGuide="body5">Submitted</Text>
		</Flexbox>
	)
}

const ContactForm = () => {
	const [validationErrors, setValidationError] = React.useState<ContactUsFormDataValidationError>(initialDetailsValidation);
	const [submissionState, setSubmissionState] = React.useState(false);
	const [isLoading, setLoading] = React.useState(false);
	const [formData, setFormData] = React.useState<ContactUsFormData>({
		fullName: "",
		email: "",
		phoneNumber: "",
		message: ""
	});

	const resetForm = React.useCallback(() => {
		setValidationError(initialDetailsValidation);
		setFormData({
			fullName: "",
			email: "",
			phoneNumber: "",
			message: ""
		})
	}, []);

	const handleFullNameChange = React.useCallback((event) => {
		const updatedFormData = {
			...formData,
			fullName: event.target.value + "",
		}
		const check = contactUsSchemaModel.checkForField("fullName", updatedFormData);
		setValidationError({
			...validationErrors,
			fullName: check
		})
		setFormData(updatedFormData)
	}, [formData, validationErrors]);

	const handleEmailChange = React.useCallback((event) => {
		const updatedFormData = {
			...formData,
			email: event.target.value + "",
		}
		const check = contactUsSchemaModel.checkForField("email", updatedFormData);
		setValidationError({
			...validationErrors,
			email: check
		})
		setFormData(updatedFormData)
	}, [formData, validationErrors]);

	const handlePhonenumberChange = React.useCallback((event) => {
		const updatedFormData = {
			...formData,
			phoneNumber: event.target.value + "",
		}
		const check = contactUsSchemaModel.checkForField("phoneNumber", updatedFormData);
		setValidationError({
			...validationErrors,
			phoneNumber: check
		})
		setFormData(updatedFormData)
	}, [formData, validationErrors]);

	const handleMessageChange = React.useCallback((event) => {
		const updatedFormData = {
			...formData,
			message: event.target.value + "",
		}
		const check = contactUsSchemaModel.checkForField("message", updatedFormData);
		setValidationError({
			...validationErrors,
			message: check
		})
		setFormData(updatedFormData)
	}, [formData, validationErrors]);

	const onFormSubmission = React.useCallback(async () => {
		if (isLoading) return;

		const check = contactUsSchemaModel.check(formData);
		setValidationError(check);

		const isFormValid = Object.values(check).every((field) => !field.hasError);
		if (!isFormValid) return;

		console.log("Form is valid: ", formData);
		try {
			setLoading(true);
			await createContactUsForm(formData);
			setSubmissionState(true);
			setTimeout(() => setSubmissionState(false), 2000);
			resetForm();
			setLoading(false);
		} catch (error) {
			setLoading(false);
		}

	}, [validationErrors, formData, isLoading])

	return (
		<ResponsiveDiv
			css={styles.contactFormWrapper}
		>
			{isLoading && (
				<ResponsiveDiv css={styles.contactFormLoader}>
					<Spinner />
				</ResponsiveDiv>
			)}
			<Input
				mb="xs"
				type="text"
				title="Full name"
				name="full_name"
				id="full_name"
				required={true}
				fullWidth
				value={formData.fullName}
				onChange={handleFullNameChange}
				validationType={validationErrors.fullName?.hasError ? "error" : "none"}
				validationMessage={validationErrors.fullName?.errorMessage || ""}
			/>
			<Flexbox direction="row" justify="space-between" align="center" gap={24}>
				<FlexboxItem colspan={11}>
					<Input
						mb="xs"
						type="email"
						title="Email"
						name="email"
						id="email"
						required={true}
						fullWidth
						value={formData.email}
						onChange={handleEmailChange}
						validationType={validationErrors.email?.hasError ? "error" : "none"}
						validationMessage={validationErrors.email?.errorMessage || ""}
					/>
				</FlexboxItem>
				<FlexboxItem colspan={11}>
					<Input
						mb="xs"
						type="text"
						title="Phone number"
						name="phone_number"
						id="phone_number"
						fullWidth
						value={formData.phoneNumber}
						onChange={handlePhonenumberChange}
						validationType={validationErrors.phoneNumber?.hasError ? "error" : "none"}
						validationMessage={validationErrors.phoneNumber?.errorMessage || ""}
					/>
				</FlexboxItem>
			</Flexbox>
			<Textarea
				mb="xs"
				title="Message"
				name="message"
				id="message"
				required={true}
				fullWidth
				rows={4}
				value={formData.message}
				onChange={handleMessageChange}
				validationType={validationErrors.message?.hasError ? "error" : "none"}
				validationMessage={validationErrors.message?.errorMessage || ""}
			/>
			<Flexbox
				direction="row"
				justify="flex-end"
				align="center"
				gap={16}
			>
				{submissionState && <SubmittedState />}
				<Button
					type="primary"
					onClick={onFormSubmission}
				>
					Send
				</Button>
			</Flexbox>
		</ResponsiveDiv>
	)
}

export default () => {
	return (
		<WidgetContainer id="contact-us">
			<Flexbox direction="row" justify="space-between" align="center" gap={16}>
				<FlexboxItem colspan={24} colspanXL={10}>
					<Animated animation="fadeInUp" duration={0.5} timingFunction="ease" iteration={1} trigger="inview">
						<Text fontStyleGuide="heading4" color="white" mb="lg" alignXL="left" align="center">
							How can we &nbsp;
							<Text as="span" fontStyleGuide="heading4" primaryGradient={true}>help&nbsp;</Text>
							you
						</Text>
						<Text as="div" fontStyleGuide="body3" color="gray-neutral" alignXL="left" align="center">
							Don't hesitate to reach out via email - <br /> admin@blitzscale.co
						</Text>
					</Animated>
				</FlexboxItem>
				<FlexboxItem colspan={24} colspanXL={12}>
					<Animated
						animation="zoomIn"
						duration={1}
						iteration={1}
						timingFunction="ease"
						trigger={"auto"}
					>
						<ContactForm />
					</Animated>
				</FlexboxItem>
			</Flexbox>
		</WidgetContainer>
	)
}
